/*
 * @Description:
 * @Version: 2.0
 * @Autor: Yaowen Liu
 * @Date: 2020-05-09 15:19:10
 * @LastEditors: Yaowen Liu
 * @LastEditTime: 2020-05-25 10:31:17
 */
import apiHelper from './apiHelper';
import {getHost} from '@/utils'

const host = getHost();
const userDomain = host + process.env.VUE_APP_USER_API_URL;

/**
 * 获取用户信息
 */
export const getCurrentUserInfo = () => {
  return apiHelper.getWidthoutLoading(`${userDomain}/getCurrentUserInfo`);
};

/**
 * 获取用户列表
 */
export const obtainUserList = (params) => {
  return apiHelper.getWidthoutLoading(`${userDomain}/obtainUserList`, params);
};

/**
 * 获取白名单列表
 */
export const obtainWhiteListAll = () => {
  return apiHelper.getWidthoutLoading(`${userDomain}/obtainWhiteListAll`);
};

/**
 * 邮箱查重
 */
export const checkEmail = (params) => {
  return apiHelper.getWidthoutLoading(`${userDomain}/checkEmail`, params);
};

/**
 * 用户名查重
 */
export const checkUsername = (params) => {
  return apiHelper.getWidthoutLoading(`${userDomain}/checkUsername`, params);
};

/**
 * 添加白名单
 */
export const addWhiteList = (params) => {
  return apiHelper.post(`${userDomain}/addWhiteList`, params);
};

/**
 * 用户注册
 */
export const register = (params) => {
  return apiHelper.post(`${userDomain}/register`, params);
};

/**
 * 用户回显数据
 */
export const userInfo = (userId) => {
  return apiHelper.getWidthoutLoading(`${userDomain}/userInfo/${userId}`);
};

/**
 * 编辑用户信息
 */
export const editUserBaseInfo = (params) => {
  return apiHelper.put(`${userDomain}/editUserBaseInfo`, params);
};

/**
 * 编辑用户状态
 */
export const editUserStatus = (userId) => {
  return apiHelper.put(`${userDomain}/editUserStatus/${userId}`);
};

/**
 * 重制密码
 */
export const resetPassword = (userId) => {
  return apiHelper.put(`${userDomain}/resetPassword/${userId}`);
};

/**
 * 手动解除账号绑定
 */
export const unlockAccount = (email) => {
  return apiHelper.put(`${userDomain}/unlockAccount/${email}`);
};

/**
 * 密码修改
 */
export const changePassword = (params) => {
  return apiHelper.put(`${userDomain}/changePassword`, params);
};
