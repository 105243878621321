/*
 * @Description:
 * @Version: 2.0
 * @Autor: Yaowen Liu
 * @Date: 2020-03-02 10:04:46
 * @LastEditors: Yaowen Liu
 * @LastEditTime: 2020-10-15 17:09:32
 */


/**
 * @description: 自定义验证规则
 * @param {type}
 * @return:
 * @author: Yaowen Liu
 */
const customValidRules = {
  phone(rule, value, callback) {
    if (!value) {
      callback(new Error('手机号不能为空'));
    } else {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号'));
      }
    }
  },

};

// 焦点判断必填
export const requireBlur = { required: true, message: '不能为空', trigger: 'blur' };

// 选择判断必填
export const requireChange = { required: true, message: '不能为空', trigger: 'change' };

// 判断数字
export const requireNumber = { required: true, type: 'number', message: '必须是数字', trigger: 'change' };

// 邮箱验证
export const requireEmail = { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' };

// 手机号验证
export const requirePhone = { validator: customValidRules.phone, trigger: 'blur' };

