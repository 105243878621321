<template>
  <div class="password-form">
    <h2 class="title">密码修改</h2>
    <el-form ref="form" :model="form" :rules="rules" labelPosition="top">
      <el-form-item prop="rawPassword" label="旧密码">
        <el-input ref="file" v-model="form.rawPassword" type="password" placeholder="你的原始密码" />
      </el-form-item>
      <el-form-item prop="newPassword" label="新密码" required>
        <el-input ref="file" v-model="form.newPassword" type="password" placeholder="8-16位的字符+数字+字母组成" />
      </el-form-item>
      <el-form-item prop="newPasswordAgain" label="确认新密码" required>
        <el-input ref="file" v-model="form.newPasswordAgain" type="password" placeholder="请与新密码保持一致" />
      </el-form-item>
      <el-form-item>
        <el-button class="full-width" type="primary" :loading="isFetching" @click="submit">{{
          isFetching ? '修改中...' : '确认修改'
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { requireBlur } from './formValidate';
import { changePassword } from './api/peopleManagement';
export default {
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  data() {
    const passReg = /^(?![A-Za-z0-9]+$)(?![A-Za-z_\W]+$)(?![0-9_\W]+$)[a-zA-Z0-9_\W]{8,}$/;
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if (!passReg.test(value)) {
        callback(new Error('密码必须为8-16位的字符+数字+字母组成'));
      } else if (value === this.form.rawPassword) {
        callback(new Error('新密码不能与当前密码相同'));
      } else {
        callback();
      }
    };
    const validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    return {
      form: {
        rawPassword: '',
        newPassword: '',
        newPasswordAgain: ''
      },
      rules: {
        rawPassword: [requireBlur],
        newPassword: { validator: validatePass, trigger: 'blur' },
        newPasswordAgain: { validator: validateConfirmPass, trigger: 'blur' }
      },
      isFetching: false
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const params = { ...this.form };
            delete params.newPasswordAgain;
            this.isFetching = true;
            const { status = '' } = await changePassword(params);
            if (status === '0') {
              this.$message('密码修改成功，重新登录');
              this.$emit('backToLogin');
            }
          } finally {
            this.isFetching = false;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.password-form {
  width: 300px;
  .title {
    font-size: 30px;
    color: #333333;
    padding-bottom: 20px;
  }
}
</style>
