export default {
    'recharge.bulkRecharge': 'Bulk Topup',
    'recharge.balance': 'Balance',
    'recharge.amount': 'Amount',
    'recharge.status': 'Status',
    'recharge.unifiedRecharge': 'Unified Topup',
    'recharge.fillIn': 'Fill in',
    'recharge.wattingNum' : 'Top up quantity',
    'recharge.wattingTotalAmount': 'Total Amount',
    'recharge.unifiedRecharge.tips': 'The unified topup amount will be batch fill in all selected accounts that need recharging.',
    'recharge.recharging': 'Top up pending',
    'recharge.rechargeSuccessful': 'Success',
    'recharge.rechargeAmount': 'Top up amount',
    'recharge.applyAmount': 'Applied amount',
    'recharge.realAmount': 'Actual amount',
    'recharge.allRechargeSccess': 'All  successful',
    'recharge.rechargeSccessTips': 'Topup successful for {number} account, total amout:',
    'recharge.rechargeAppliedSccessTips': `The top-up application for {number} account has been submitted, the total amount is`,
    'recharge.rechargeTime':'Top up time',
    'recharge.rechargeType':'Top up type',

  };
  