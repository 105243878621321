<!--
 * @Description:
 * @Version: 2.0
 * @Author: Yaowen Liu
 * @Date: 2021-05-11 15:56:53
 * @LastEditors: Yaowen Liu
 * @LastEditTime: 2022-03-10 12:07:16
-->
<template>
  <div ref="svgbox" />
</template>

<script>
import lottie from 'lottie-web';
import animationData from '@/assets/lottie-files/3145-bomb.json';
export default {
  mounted() {
    const lottieEle = this.$refs.svgbox;
    lottieEle.style.width = '100px';
    lottieEle.style.height = '100px';
    this.errorPlayer = lottie.loadAnimation({
      container: lottieEle,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    });
  },

  beforeDestroy() {
    this.errorPlayer.destroy();
  }
};
</script>

<style lang="scss" scoped></style>
