import Vue from 'vue';

import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import commonZh from './zh-CN';
import commonEn from './en-US';

Vue.use(VueI18n)  // 全局注册国际化包

const messages = {
    'en': {
        ...commonEn,
        ...enLocale
    },
    'zh': {
        ...commonZh,
        ...zhLocale
    }
}
const broswerLang= navigator.language==='zh-CN'?'zh':'en';
const userLang = window.localStorage.getItem('lang')
const cLang = (userLang===''||userLang===null)?broswerLang:userLang

window.localStorage.setItem('lang',cLang)

const i18n = new VueI18n({
    locale: cLang,   // 初始化中文
    messages
});
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n;