import menu from './en-US/menu';
import pages from './en-US/pages';
import settings from './en-US/settings';
import picker from './en-US/picker';
import table from './en-US/table';
import recharge from './en-US/recharge';
import common from './en-US/common';
import account from './en-US/account';

export default {
    ...menu,
    ...settings,
    ...pages,
    ...common,
    ...picker,
    ...table,
    ...recharge,
    ...account,
};
