import menu from './zh-CN/menu.js';
import pages from './zh-CN/pages.js';
import settings from './zh-CN/settings.js';
import picker from './zh-CN/picker.js';
import table from './zh-CN/table';
import common from './zh-CN/common.js';
import recharge from './zh-CN/recharge.js';
import account from './zh-CN/account.js';

export default {
    ...pages,
    ...menu,
    ...settings,
    ...common,
    ...picker,
    ...table,
    ...recharge,
    ...account,
};
