<!--
 * @Description:
 * @Version: 2.0
 * @Author: Yaowen Liu
 * @Date: 2021-05-11 15:46:47
 * @LastEditors: Yaowen Liu
 * @LastEditTime: 2022-03-10 12:07:10
-->
<template>
  <div class="web-login-wrapper">
    <div class="left-wrapper">
      <h1 class="title">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <text x="0" y="30" class="text">WiteMedia</text>
        </svg>
      </h1>
      <div class="blank" />
      <svg-animation class="svg-animation" />
    </div>
    <div class="right-wrapper">
      <div class="back">
        <div v-if="tabName === 'website'" class="button" @click="handleLogout">
          <span class="icon el-icon-arrow-left" />
          <p class="text">{{ $t('menu.account.logout') }}</p>
        </div>
        <div v-if="tabName === 'password'" class="button" @click="handleLogout">
          <span class="icon el-icon-arrow-left" />
          <p class="text">{{$t('menu.account.logout')}}</p>
        </div>
        <div class="sub-title">
          <img src="@/assets/logo.png" class="title-logo" >
        </div>
      </div>
      <div class="content">
        <loading-state v-if="tabName === ''" />
        <form-login v-if="tabName === 'login'" :unique="unique" @changePassword="changePassword" @selectWebsit="selectWebsit" />
        <form-password v-else-if="tabName === 'password'" @backToLogin="tabName = 'login'" />
        <website-selector :unique="unique" v-else-if="tabName === 'website'" />
      </div>
      <footer class="terms-footer">
        <a href="/privacy.html" target="_blank">Privacy</a>
        <el-divider direction="vertical" />
        <a href="/termsOfService.html" target="_blank">About Us</a>
        <el-divider direction="vertical" />
        <a href="/aboutus.html" target="_blank">Terms of Service</a>
      </footer>
    </div>
  </div>
</template>

<script>
import SvgAnimation from './SvgAnimation';
import LoadingState from './LoadingState';
import FormLogin from './FormLogin';
import FormPassword from './FormPassword';
import WebsiteSelector from './WebsiteSelector';
import {getHost} from '@/utils'
import { bindUnique } from './api/user';
import { v4 as uuidV4 } from 'uuid';
export default {
  components: {
    SvgAnimation,
    LoadingState,
    FormLogin,
    FormPassword,
    WebsiteSelector
  },

  data() {
    return {
      tabName: '',
      unique:'',
      redirectUrl:''
    };
  },

  async mounted() {
    const isLogin = await this.isLogin();
    console.log("isLogin",isLogin)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const unique = urlParams.get('unique')||uuidV4(16);
    let  redirectUrl = urlParams.get('redirect')
    if(redirectUrl&&redirectUrl.indexOf(unique)===-1){
      redirectUrl=`${redirectUrl}?unique=${unique}`
    }
    this.unique=unique;
    this.redirectUrl=redirectUrl;

    if (isLogin) {
      if(redirectUrl) {
        bindUnique({unique}).then((res)=>{
          if(res.status==='0'){
            window.location.replace(`${redirectUrl}`)
          }
        })
      }else{
        this.tabName = 'website';
      }
    } else {
      this.tabName = 'login';
    }
  },

  methods: {
    // 判断是否登陆
    isLogin() {
      const token = localStorage.getItem('token');
      if (!token) {
        return false;
      }
      const host =getHost();

      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${host}${process.env.VUE_APP_USER_API_URL}/getCurrentUserInfo/google`, true);
        xhr.setRequestHeader('Authorization', token);
        xhr.setRequestHeader('content-type', 'application/json; charset=UTF-8');
        xhr.onload = function () {
          var content = JSON.parse(xhr.response);
          setTimeout(() => {
            resolve(content.status === '0');
          }, 500);
        };
        xhr.send();
      });
    },

    // 修改密码
    changePassword(data) {
      this.tabName = 'password';
      this.setLoginInfo(data);
    },

    // 选择站点
    selectWebsit(data) {
      this.setLoginInfo(data)
      this.tabName= 'website'
        
    },

    // 设置token登陆信息
    setLoginInfo(data) {
      const { token, menuType,jarvisEmail } = data;
      if(jarvisEmail){
        localStorage.setItem('jarvisEmail', jarvisEmail);
      } 
      localStorage.setItem('token', token);
      localStorage.setItem('WebsitePermission', menuType);

    },

    // 注销
    handleLogout() {
      localStorage.removeItem('jarvisEmail');
      localStorage.removeItem('token');
      localStorage.removeItem('WebsitePermission');
      this.tabName = 'login';
    }
  }
};
</script>

<style lang="scss" scoped>
.web-login-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .left-wrapper {
    width: 60%;
    height: 100%;
    display: grid;
    place-items: center;
    position: relative;
    overflow: hidden;
    background-color: #409eff;

    .title {
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 9;
      .text {
        font-size: 30px;
        fill: none;
        stroke: #ffffff;
        stroke-width: 2px;
        stroke-dasharray: 10, 100;
        stroke-dashoffset: 10;
        animation: draw 3s linear forwards;
      }
      .text:hover {
        animation: draw 3s linear infinite;
      }
      @keyframes draw {
        to {
          stroke-dasharray: 340;
          stroke-dashoffset: 0;
          fill: #ffffff;
          stroke: none;
          stroke-width: 0;
        }
      }
    }

    .blank {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      transform: scale(2) rotate(45deg) translate3d(-50%, 0, 0);
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 40%;
        background-color: #5ba3fc;
      }
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 60%;
        background-color: #6cacfd;
      }
    }

    .svg-animation {
      position: relative;
      z-index: 2;
    }
  }
  .right-wrapper {
    width: 40%;
    height: 100%;
    background-color: #ffffff;
    display: grid;
    grid-template-rows: max-content 1fr max-content;

    .back {
      height: 40px;
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 0.6;
        color: #666666;
        .icon {
          padding: 0 10px;
          font-size: 24px;
          font-weight: 900;
        }
        .text {
          font-size: 18px;
        }
        &:hover {
          opacity: 1;
        }
      }
      .sub-title{
        font-size: 16px;
        font-weight: bold;
        color:#666;
        margin:0 14px;
        .title-logo{
          width:120px;
        }
      }
    }

    .content {
      display: grid;
      place-items: center;
    }

    .terms-footer {
      padding: 10px 0;
      border-top: 1px solid #f2f2f2;
      display: flex;
      justify-content: center;
      a {
        font-size: 14px;
        color: #666666;
        opacity: 0.6;
        &:hover {
          opacity: 1;
          text-decoration: underline;
        }
      }
    }
  }
}
@media screen and (max-width:960px) {
  .web-login-wrapper{
    .left-wrapper{
      display: none;
    }
    .right-wrapper{
      width: 100%;
    }
  }

}
</style>
