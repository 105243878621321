export default {
    'account.openningApplication':'New Application',
    'account.openningType':'Type of Account Opening',
    'account.link': 'App link',
    'account.fanpage': 'Fanpage',
    'account.openingPackage': 'Account Package',
    'account.fillInTitle':'Fill in the information',
    'account.fillInTips':'Please make sure that the pages are new ones that have never been used to open accounts before.',
    'account.accNumber':'Account numbers',
    'account.reviewSucdess':'Opening Success',
    'account.reviewFail':'Opening Fail',
    'account.paymentType':'Payment type',
    'account.serviceCharge':'Service charge',
    'account.paymentAmount':'Payment amount',
    'account.paymentDate':'Payment Date',
    'account.actualReceivedAmount':'Actual received amount',
    'account.addPaymentRecord':'Add Payment Record',
    'account.editPaymentRecord':'Edit Payment Record',
    'account.advertiserStatus':'Account status',
    'account.frozenBalance':'Frozen Balance',
    'account.confirmReset':'Confirm Clear',
    'account.batchreset': 'batch Clear',
    'account.batchBindding': 'Batch binding',
    'account.BinddingAudit': 'Bindding Audit',
    'account.BinddingSuccess': 'Bindding Success',
    'account.BinddingFail': 'Bindding Fail',
    'account.openSuccessNum': 'Number of accounts',
    'account.openingNum': 'Pending accounts',
    'account.pageTyps': 'Page types',
    'account.openingAudit': 'Opening Audit',
    'account.importExcel': 'Import by excel',
    'account.importHeaderError':'The header does not meet the requirements',
    'account.importSuccess':'import success',
    'account.openStatus':'Status',
    'account.enableNum':'Enable',
    'account.disableNum':'Disable',
    'account.customer': 'Customer',
    'account.fanpageLinkTips':'Fanpage and link must correspond one to one',
    'account.profileLinkTips':'For receiving home page',
    'account.agentProfileLinkTips':'Please add this profile link as an administrator of your fp for account opening.',   
    'account.advertiserCompany':'Advertiser Company'
}