<!--
 * @Description:
 * @Version: 2.0
 * @Author: Yaowen Liu
 * @Date: 2021-05-11 15:55:03
 * @LastEditors: Yaowen Liu
 * @LastEditTime: 2022-03-10 12:07:22
-->
<template>
  <div class="login-form">
    <img src="@/assets/logo.png" class="logo" >
    <h2 class="title">{{ $t('menu.login') }}</h2>
    <el-form ref="form" :model="form" :rules="rules" size="medium" labelPosition="top">
      <el-form-item prop="username">
        <el-input v-model="form.username" type="username" size="large" @keyup.enter.native="handleLogin">
          <el-button slot="prepend" icon="el-icon-user" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input ref="file" v-model="form.password" type="password" size="large" @keyup.enter.native="handleLogin">
          <el-button slot="prepend" icon="el-icon-key" />
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="full-width" type="primary" :loading="loading" @click="handleLogin()">{{
          loading ? $t('common.LoggingIn') +'...' : $t('menu.login')
        }}</el-button>
        <div style="float: right;">
          <el-popover
            placement="bottom-end"
            title="登陆不了？"
            width="360"
            trigger="hover">
            <div>
              <div>
                1.检查网络环境是否正常如能访问google仍不能登陆，请联系网络管理员为你您解决。
              </div>
              <div>
                2.或关闭VPN,刷新页面继续登陆,本站访问无需外网。
              </div>
              <div>
                3.如以上方法都不能尝试登陆，请联系管理员为你解决。
              </div>
            </div>
            <div slot="reference">
              <el-link   type="info" :underline="false">登陆遇到问题
                <span class="el-icon-question">
              </span>
              </el-link>
            </div>
          </el-popover>
        </div>
      </el-form-item>
      <el-form-item v-if="testEnv">
        <div>测试账号(仅测试可见)：</div>
        <el-link style="margin-right:12px" v-for="(account,index) in testAccount" :key="`${index}:account`" @click="()=>setTestAccount(account)">{{ account.username }}</el-link>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login } from './api/user';
export default {
  props:{
    unique:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入你的WiteMedia企业邮箱',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ]
      },
      loading: false,
      googleEnable:false,
      switchLoading:false,
      testEnv:false,
      testAccount:[
        {username:'lcl@163.com',password:'@qwer1234'},
        {username:'tt1@163.com',password:'@qwer1234'},
        {username:'tt2@163.com',password:'@qwer1234'},
        {username:'bd1@163.com',password:'@qwer1234'},
        {username:'cw1@163.com',password:'@qwer1234'},
      ]
    };
  },
  mounted(){
    if(window.location.search.indexOf('googleEnable=true')>0){
      this.googleEnable=true
    }
    console.log("process.env.NODE_ENV--",process.env.NODE_ENV)
    if(process.env.NODE_ENV === 'development'&&window.localStorage.getItem('allowTest')){
      this.testEnv=true
    }
  },
  methods: {
    changeLoginEnv(){
      this.switchLoading=true
      location.replace(`/login?googleEnable=${!this.googleEnable}`);
    },
    /*global grecaptcha */
    handleLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = { ...this.form,unique:this.unique };
          try {            
            if (grecaptcha) {
              // 如果是生产环境则需要校验验证码;
              grecaptcha.ready(()=> {            
                grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_KEY).then((code) => {
                  this.login(params, code);
                });
              })
            } else {
              // 本地开发环境不需要验证码
              this.login(params);
            }
          } catch (error) {
            this.login(params);
          }
        } else {
          return false;
        }
      });
    },
    async login(params, code) {
      const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const  redirectUrl = urlParams.get('redirect')
      try {
        this.loading = true;
        
        const { status = '', data = {}, message } = await login(params, code);
        if (status === '0') {
          window.localStorage.setItem('allowTest',true)
          if (data.passwordExpired) {
            this.$emit('changePassword', data);
          } else {
            if(redirectUrl){
              window.location.replace(redirectUrl);
            }else{
              this.$emit('selectWebsit', data);
            }
          }
        } else {
          this.$message.error(message);
        }
      } finally {
        setTimeout(()=>{
          this.loading = false;
        },2000)
      }
    },
    setTestAccount(account){
      this.form=account;
    },


}
}
</script>

<style lang="scss" >
.login-form {
  width: 300px;
  .logo {
      width: 300px;
      margin:0 auto 80px;
      cursor: pointer;
    }
  .title {
    font-size: 30px;
    color: #333333;
    padding-bottom: 20px;
  }
}
</style>
