<!--
 * @Description:
 * @Version: 2.0
 * @Author: Yaowen Liu
 * @Date: 2021-05-11 17:43:29
 * @LastEditors: Yaowen Liu
 * @LastEditTime: 2022-03-10 12:07:07
-->
<template>
  <div ref="svgbox" />
</template>

<script>
import lottie from 'lottie-web';
// import animationData from '@/assets/lottie-files/18365-animaton-for-e-learning-web-site.json';
export default {
  props: {
    animationData: {
      type: Object
    },
    width: {
      type: Number,
      default: 80
    },
    height: {
      type: Number,
      default: 80
    }
  },
  mounted() {
    const lottieEle = this.$refs.svgbox;
    lottieEle.style.width = `${this.width}px`;
    lottieEle.style.height = `${this.height}px`;
    this.errorPlayer = lottie.loadAnimation({
      container: lottieEle,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: this.animationData
    });
  },

  beforeDestroy() {
    this.errorPlayer.destroy();
  }
};
</script>

<style lang="scss" scoped></style>
