export default {
    'recharge.bulkRecharge': '批量充值',
    'recharge.unifiedRecharge': '统一充值',
    'recharge.fillIn': '引用',
    'recharge.amount': '金额',
    'recharge.wattingNum' : '待充值账号数量',
    'recharge.wattingTotalAmount': '本次待充值总金额',
    'recharge.unifiedRecharge.tips': '统一充值金额可以批量引用给所有已选择需要充值的账号',
    'recharge.recharging': '充值中',
    'recharge.rechargeSuccessful': '充值成功',
    'recharge.rechargeAmount': '充值金额',
    'recharge.applyAmount': '申请充值金额',
    'recharge.realAmount': '实际充值金额',
    'recharge.allRechargeSccess': '全部充值成功',
    'recharge.rechargeSccessTips': `{number}条账号全部充值成功共计金额`,
    'recharge.rechargeAppliedSccessTips': `{number}条充值申请提交成功，共计金额`,
    'recharge.rechargeTime':'充值时间',
    'recharge.rechargeType':'充值类型',

  };
  