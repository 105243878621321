/*
 * @Description:
 * @Version: 2.0
 * @Autor: Yaowen Liu
 * @Date: 2020-03-23 17:40:43
 * @LastEditors: Yaowen Liu
 * @LastEditTime: 2020-06-10 11:13:42
 */
import axios from 'axios';
import apiHelper from './apiHelper';
import qs from 'qs';
import {getHost} from '@/utils'
const host = getHost();
// axios.defaults.withCredentials = true;

export const login = (params, code) => {
  return new Promise((resolve, reject) => {
    const url = `${host}${process.env.VUE_APP_LG_API_URL}/login?code=${code}&unique=${params.unique}`;
    axios({
      method: 'POST',
      url: url,
      data: qs.stringify(params),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject();
      }
    });
  });
};


export const bindUnique = (params) => {
  const url = `${host}${process.env.VUE_APP_LG_API_URL}/cacheLogin?unique=${params.unique}`;
  return apiHelper.post(url,params)
};

