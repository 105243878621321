export default {
    'common.recharge': 'Recharge',
    'common.balance': 'Balance',
    'common.status': 'Status',
    'common.submit': 'Submit',
    'common.pending': 'Pending',
    'common.error': 'Error',
    'common.fail': 'Fail',
    'common.success': 'Success',
    'common.account': 'Account',
    'common.accountName': 'Account name',
    'common.confirm': 'Confirm',
    'common.cancel': 'Cancel',
    'common.plaform': 'Plaform',
    'common.loginExpire': 'Login expired',
    'common.loginExpire.tips': 'Your login has expired. Please log in again to continue.',
    'common.loading': 'Loading',
    'common.expend': 'Expend',
    'common.requestError': 'Request error',
    'common.LoggingIn': 'Logging in',
    'common.search': 'Search',
    'common.reset': 'Reset',
    'common.searchPlaceholder': 'Enter keywords to search...',
    'common.searchBmOrOrderNoPlaceholder': 'Enter BM id or order number to search...',
    'common.reviewStatus': 'Review Status',
    'common.review': 'Review',
    'common.openingStatus': 'Opening Status',
    'common.applyDate': 'Apply Date',
    'common.paymentDate': 'Payment Date',
    'common.rechargeDate': 'Recharge Date',
    'common.activityDate': 'Activity Date',
    'common.date': 'Date',
    'common.types': 'Types',
    'common.paymentMethods':'Payment Methods',
    'common.annountId':'Annount ID',
    'common.annount':'Annount',
    'common.timezone': 'Time zone',
    'common.detail': 'Detail',
    'common.delete': 'Delete',
    'common.edit': 'Edit',
    'common.deleteConfirm':'Delete or not',
    'common.customer':'Customer',
    'common.submitted':'submitted',
    'common.actonSuccess':'Operate successfully',
    'common.binding':'Binding',
    'common.accountInfo':'Account info',
    'common.selected':'{num} items have been selected',
    'common.remark':'Remark',
    'common.remarkRequire':'Remark is require',
    'common.orderNo':'Order No',
    'common.openType':'Opening Type',
    'common.openPage':'Pages ',
    'common.pageNum':'Page Numbers',
    'common.spend':'Spend',
    'common.referenceOnly':'Reference only',
    'common.accountId':'Account Id',
    'common.accountType':'Account Type',
    'common.balanceLimit':'Balance',  
    'common.operator':'Operator',
    'common.applyTime':'Applied time',
    'common.advertiserName':'Advertiser Name',
    'common.advertiserID':'Advertiser ID',
    'common.completeTime':'Complete time',
    'common.customerName':' Customer Name',
    'account.saving': 'Saving',
    'common.updateTime':'Update Time',
    'common.uploadTemplate':'Upload template download',
    'common.isRequired':'is requied',
    'common.selectAll':'Select All',
    'common.clear':'Clear',
    'common.autoSelect':'Auto Select',
    'common.autoSelect.tips':'Paste values are automatically checked',
    'common.billType':'Bill type',
    'common.userManual':'Click to view the operation manual',
    'common.channel': 'Channel',
    'common.view': 'View',
  };
  