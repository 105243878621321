export default {
    'picker.today': '今天',
    'picker.yesterday': '昨天',
    'picker.twoDaysAgo': '前天',
    'picker.thisWeek': '本周',
    'picker.thisMonth': '本月',
    'picker.thisYear': '今年',
    'picker.lastMonth': '上月',
    'picker.pastWeek': '最近一周',
    'picker.pastMonth': '最近一个月',
    'picker.pastThreeMonths': '最近三个月',
    'picker.to':'至',
    'picker.startDate':'开始日期',
    'picker.endDate':'结束日期',
    'picker.noOptions':'暂无任何选项',
    'picker.plaseSelect':'请选择',
  };
  