export default {
    'common.recharge': '充值',
    'common.balance': '余额',
    'common.status': '状态',
    'common.submit': '提交',
    'common.pending': '等待中',
    'common.error': '错误',
    'common.fail': '失败',
    'common.success': '成功',
    'common.account': '账号',
    'common.accountName': '账号名称',
    'common.confirm': '确定',
    'common.cancel': '取消',
    'common.plaform': '平台',
    'common.loginExpire': '登陆过期',
    'common.loginExpire.tips': '你的登陆已过期，请重新登陆后继续.',
    'common.loading': '加载中',
    'common.expend': '展开搜索',
    'common.requestError': '请求失败',
    'common.LoggingIn': '登陆中',
    'common.search': '搜索',
    'common.reset': '重置',
    'common.searchPlaceholder': '清输入关键字搜索',
    'common.searchBmOrOrderNoPlaceholder': '清输入BM id或订单编号搜索',
    'common.reviewStatus': '审核状态',
    'common.review': '审核',
    'common.openingStatus': '开户状态',
    'common.applyDate': '申请时间',
    'common.date': '日期',
    'common.paymentDate': '付款日期',
    'common.rechargeDate': '充值日期',
    'common.activityDate': '操作日期',
    'common.types': '类型',
    'common.paymentMethods':'付款方式',
    'common.annountId':'账户ID',
    'common.annount':'账户',
    'common.timezone': '时区',
    'common.detail': '详情',
    'common.delete': '删除',
    'common.edit': '编辑',
    'common.deleteConfirm':'确认删除',
    'common.customer':'客户',
    'common.submitted':'提交成功',
    'common.actonSuccess':'操作成功',
    'common.binding':'绑定',
    'common.accountInfo':'账号信息',
    'common.selected':'已选择{num}条',
    'common.remark':'备注',
    'common.remarkRequire':'备注必填',
    'common.orderNo':'订单编号',
    'common.openType':'开户类型',
    'common.openPage':'开户主页',
    'common.pageNum':'主页数量',
    'common.spend':'消耗',
    'common.referenceOnly':'仅供参考',
    'common.accountId':'账号ID',
    'common.accountType':'账号类型',
    'common.balanceLimit':'钱包额度',
    'common.operator':'操作者',
    'common.applyTime':'申请时间',
    'common.advertiserName':'广告账号名称',
    'common.advertiserID':'广告账号ID',
    'common.completeTime':'完成时间',
    'common.customerName':'客户名称',
    'account.pageTyps': '主页类型',
    'common.saving': '保存中',
    'common.updateTime':'更新时间',
    'common.uploadTemplate':'导入模版下载',
    'common.isRequired':'为必填',
    'common.selectAll':'全选',
    'common.clear':'清空',
    'common.autoSelect':'自动勾选',
    'common.autoSelect.tips':'可从excle粘贴值点击勾选',
    'common.billType':'账单类型',
    'common.userManual':'点击查看用户手册',
    'common.channel': '渠道',
    'common.view': '查看',
  };
  