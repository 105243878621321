<template>
  <div id="app">
    <WebLogin  :loginBtnDisabled="loginBtnDisabled"/>
  </div>
</template>

<script>
import WebLogin from './WebLogin.vue'

export default {
  name: 'App',
  components: {
    WebLogin
  },
  data(){
    return {
      loginBtnDisabled:false
    }
  },
  mounted() {
    // recaptcha 暂不开启 2024.8.29
    // 如果是开发环境则需要加载验证码js
    // if (process.env.NODE_ENV === 'production') {
    //   this.loginBtnDisabled = true;
    //   this.setCodeScript();
    // }

    document.addEventListener('DOMContentLoaded', () => {
      this.loginBtnDisabled = false;
    });
  },
  methods:{
    /**
     * 验证码script标签
     */
    setCodeScript () {
      const ggUrl = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_KEY}`
      const url = `https://www.recaptcha.net/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_KEY}`
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('defer', 'defer');
      scriptTag.src = this.googleEnable?ggUrl:url;
      document.head.appendChild(scriptTag);
      scriptTag.onerror = function () {
        const script = document.createElement('script');
        script.setAttribute('defer', 'defer');
        script.src = this.googleEnable?url:ggUrl;
        document.head.appendChild(script);
      }
    }
  }
}
</script>

<style>

</style>
