export default {
    'account.openningApplication':'开户申请',
    'account.openningType':'开户类型',
    'account.link': 'App 链接',
    'account.fanpage': '开户账号 fanpage',
    'account.openingPackage':'账号包',
    'account.fillInTitle':'完善信息',
    'account.fillInTips':'使用过的粉丝页将导致开户失败，请谨慎使用!',
    'account.accNumber':'账号数量',
    'account.reviewSucdess':'确认开户',
    'account.reviewFail':'开户失败',
    'account.paymentType':'付款方式',
    'account.serviceCharge':'服务费',
    'account.paymentAmount':'转账金额',
    'account.paymentDate':'转账日期',
    'account.actualReceivedAmount':'实际到账金额',
    'account.addPaymentRecord':'新增支付记录',
    'account.editPaymentRecord':'编辑支付记录',
    'account.advertiserStatus':'账号状态',
    'account.frozenBalance':'封停余额',
    'account.confirmReset':'确认清零',
    'account.batchreset': '批量清零',
    'account.batchBindding': '批量绑定',
    'account.BinddingAudit': '绑定审核',
    'account.BinddingSuccess': '绑定成功',
    'account.BinddingFail': '绑定失败',
    'account.openSuccessNum': '开户成功数量 ',
    'account.openingNum': '开户中数量',
    'account.openingAudit': '开户审核',
    'account.importExcel': 'Excel导入',
    'account.importHeaderError':'表头不符合要求',
    'account.importSuccess':'数据导入成功',
    'account.openStatus':'开户状态',
    'account.enableNum':'正常',
    'account.disableNum':'封停:',
    'account.customer': '客户',
    'account.fanpageLinkTips':'Fanpage和 link需要一一对应',
    'account.profileLinkTips':'用于接收主页',
    'account.agentProfileLinkTips':'"请将此profile link添加为您的账户开通的管理员。"',   
    'account.advertiserCompany':'主体'

}