export default {
    'picker.today': 'Today',
    'picker.yesterday': 'Yesterday',
    'picker.twoDaysAgo': 'Two days ago',
    'picker.thisWeek': 'This week',
    'picker.thisMonth': 'This month',
    'picker.thisYear': 'This year',
    'picker.lastMonth': 'Last month',
    'picker.pastWeek': 'Last week',
    'picker.pastMonth': 'Past month',
    'picker.pastThreeMonths': 'Past three months',
    'picker.to':'To',
    'picker.startDate':'Start date',
    'picker.endDate':'End date',
    'picker.noOptions':'No options available',
    'picker.plaseSelect':'Place select',


  };
  